import _ from 'underscore';
import { promisifyObservable } from './promisify-observable';

class SubscriptionObserver {
  constructor({
    start = _.noop,
    next = _.noop,
    error = _.noop,
    complete = _.noop,
  }, subscription) {
    this.next = next;
    this.error = error;
    this.complete = complete;
    start(subscription);
    this.subscription = subscription;
  }

  get closed() {
    return this.subscription.closed;
  }
}

class Subscription {
  constructor(observer, subscriber) {
    this.observer = new SubscriptionObserver(observer, this);
    this.unsubscribeCallback = subscriber(this.observer) || _.noop;
    this.isClosed = false;
  }

  unsubscribe() {
    this.unsubscribeCallback();
    this.isClosed = true;
  }

  get closed() {
    return this.isClosed;
  }
}

/**
 * A minimum implementation of https://github.com/tc39/proposal-observable
 */
export class Observable {
  constructor(subscriber) {
    this.subscriber = subscriber;
  }

  subscribe(observer) {
    return new Subscription(observer, this.subscriber);
  }

  toPromise() {
    return promisifyObservable(this);
  }
}
