

import { CivilDate } from 'tc39-proposal-temporal';
import {
  getCivilDateDayOfWeek,
  getCivilDateMonthStart,
  getThisMonday,
} from '../index';

export const RANGES = {
  Yesterday: 1,
  Last7Days: 2,
  Today: 3,
  Custom: 4,
  Last30Days: 5,
  Last14Days: 6,
  ThisWeek: 7,
  ThisWeekMon: 8,
  LastWeek: 9,
  ThisMonth: 10,
  LastMonth: 11,
  Last3Months: 12,
  Last6Months: 13,
  LastWeekMon: 14,
  ThisYear: 15,
  LastYear: 16,
  EntireTimeLast18Months: 17,
  EntireTimeLast5Years: 18,
  EntireTime: 22,
};

export const DefaultSpecificRanges = (i18n, timeZone) => {
  const todayObj = i18n.getToday({ timeZone });
  const today = new CivilDate(todayObj.year, todayObj.month, todayObj.day);
  const yesterday = today.plus({ days: -1 });
  const thisSunday = today.plus({ days: -getCivilDateDayOfWeek(today) });
  const thisMonday = getThisMonday(today);
  const last7day = today.plus({ days: -7 });
  const last14day = today.plus({ days: -14 });
  const last30day = today.plus({ days: -30 });
  const lastMonday = today.plus({ days: -getCivilDateDayOfWeek(today) - 6 });
  const lastSunday = today.plus({ days: -getCivilDateDayOfWeek(today) - 7 });
  const lastSaturday = today.plus({ days: -getCivilDateDayOfWeek(today) - 1 });
  const thisMonthFirstDay = getCivilDateMonthStart(today);
  const lastMonthlastDay = thisMonthFirstDay.plus({ days: -1 });
  const lastMonthFirstDay = getCivilDateMonthStart(lastMonthlastDay);
  const minSupportDay = today.plus({ years: -3 });

  return [
    {
      rangeId: RANGES.Custom, key: 'Custom', range: { start: undefined, end: undefined },
    },
    {
      rangeId: RANGES.Today, key: 'Today', range: { start: today, end: today },
    },
    {
      rangeId: RANGES.Yesterday, key: 'Yesterday', range: { start: yesterday, end: yesterday },
    },
    {
      rangeId: RANGES.ThisWeek, key: 'ThisWeek', range: { start: thisSunday, end: today },
    },
    {
      rangeId: RANGES.ThisWeekMon, key: 'ThisWeekMon', range: { start: thisMonday, end: today },
    },
    {
      rangeId: RANGES.Last7Days, key: 'Last7Days', range: { start: last7day, end: yesterday },
    },
    {
      rangeId: RANGES.LastWeek, key: 'LastWeek', range: { start: lastSunday, end: lastSaturday },
    },
    {
      rangeId: RANGES.LastWeekMon, key: 'LastWeekMon', range: { start: lastMonday, end: thisSunday },
    },
    {
      rangeId: RANGES.Last14Days, key: 'Last14Days', range: { start: last14day, end: yesterday },
    },
    {
      rangeId: RANGES.ThisMonth, key: 'ThisMonth', range: { start: thisMonthFirstDay, end: today },
    },
    {
      rangeId: RANGES.LastMonth, key: 'LastMonth', range: { start: lastMonthFirstDay, end: lastMonthlastDay },
    },
    {
      rangeId: RANGES.Last30Days, key: 'Last30Days', range: { start: last30day, end: yesterday },
    },
    {
      rangeId: RANGES.EntireTime, key: 'EntireTime', range: { start: minSupportDay, end: today },
    },
  ];
};
