/** @module @bingads-webui-universal/time-aware-cache */

/**
 * Version of the package
 * @type {string}
 */

export {
  /**
   * The TimeAwareCache class
   * @see TimeAwareCache
   */
  TimeAwareCache,
} from './src/time-aware-cache';

export {
  /**
   * The expiration time for invalidated items
   * @constant number
   */
  EXP_INVALIDATED
} from './src/constants';

export {
  /**
   * The ReplacementStrategy class
   * @see ReplacementStrategy
   */
  ReplacementStrategy,

  /**
   * The UnlimitedReplacementStrategy class
   * @see UnlimitedReplacementStrategy
   */
  UnlimitedReplacementStrategy,

  /**
   * The LRUReplacementStrategy class
   * @see LRUReplacementStrategy
   */
  LRUReplacementStrategy,

  /**
   * The default cache strategy
   * @type UnlimitedReplacementStrategy
   */
  defaultStrategy,
} from './src/replacement-strategy/index';
