/** @exports @bingads-webui/time-aware-cache */

import { ReplacementStrategy } from './base';

export { LRUReplacementStrategy } from './lru';
export { ReplacementStrategy };

/**
 * The unlimited cache replacement strategy class, assume the cache can have
 * unlimited entries
 * @extends ReplacementStrategy
 */
export class UnlimitedReplacementStrategy extends ReplacementStrategy {}

export const defaultStrategy = new UnlimitedReplacementStrategy();
