/* eslint-env node */

// same as enum TimeZone defined in
//   \\AdsAppUI\private\UI\Common\Microsoft.AdCenter.UI.Advertiser.BusinessEntities
//     \Common\Enums\Enums.cs
// For iana-tz-data, see
//   https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
//   https://github.com/eggert/tz
//   https://www.iana.org/time-zones/repository/tz-link.html
//   http://www.unicode.org/repos/cldr/trunk/common/bcp47/timezone.xml
// There're several mismatched time zones between ours and iana ones,
// that's because we prefer capital, but iana prefers largest city
// offset is defined by ourselves, which doesn't consider Daylight Saving Time
module.exports = {
  AbuDhabiMuscat: {
    legacy: 32,
    offset: '+04:00',
    // Abu Dhabi is missing in iana-tz-data, use Asia/Dubai for Abu Dhabi (United Arab Emirates)
    iana: ['Asia/Dubai', 'Asia/Muscat'],
  },
  Adelaide: {
    legacy: 11,
    offset: '+09:30',
    // Australia/Adelaide has UTC offset +09:30/+10:30
    iana: ['Australia/Adelaide'],
  },
  Alaska: {
    legacy: 72,
    offset: '-09:00',
    // Australia/Adelaide has UTC offset -09:00/-08:00
    iana: ['US/Alaska'],
  },
  Almaty_Novosibirsk: {
    legacy: 25,
    offset: '+06:00',
    // Asia/Almaty has UTC offset +06:00/+06:00
    // Asia/Novosibirsk has +07:00/+07:00
    iana: ['Asia/Almaty', 'Asia/Novosibirsk'],
  },
  AmsterdamBerlinBernRomeStockholmVienna: {
    legacy: 48,
    offset: '+01:00',
    // Bern is missing in iana-tz-data, use Europe/Zurich for Bern (Switzerland)
    // All these iana time zone has UTC offset +01:00/+02:00
    iana: ['Europe/Amsterdam', 'Europe/Berlin', 'Europe/Zurich', 'Europe/Rome', 'Europe/Stockholm', 'Europe/Vienna'],
  },
  Arizona: {
    legacy: 68,
    offset: '-07:00',
    iana: ['US/Arizona'],
  },
  AstanaDhaka: {
    legacy: 24,
    offset: '+06:00',
    // Astana is missing in iana-tz-data, use Asia/Almaty for Astana (Kazakhstan)
    iana: ['Asia/Almaty', 'Asia/Dhaka'],
  },
  AthensIslandanbulMinsk: {
    legacy: 43,
    offset: '+02:00',
    // Islandanbul should be misspelled Istanbul
    // Europe/Athens has UTC offset +02:00/+03:00
    // other 2 have UTC offset +03:00/+03:00
    iana: ['Europe/Athens', 'Europe/Istanbul', 'Europe/Minsk'],
  },
  AtlanticTimeCanada: {
    legacy: 58,
    offset: '-04:00',
    // Canada/Atlantic has UTC offset -04:00/-03:00
    iana: ['Canada/Atlantic'],
  },
  AucklandWellington: {
    legacy: 3,
    offset: '+12:00',
    // Wellington is missing in iana-tz-data, use NZ for Wellington (New Zealand)
    // Both these iana time zones have UTC offset +12:00/+13:00
    iana: ['Pacific/Auckland', 'NZ'],
  },
  Azores: {
    legacy: 51,
    offset: '-01:00',
    // Atlantic/Azores has UTC offset -01:00/+00:00
    iana: ['Atlantic/Azores'],
  },
  Baghdad: {
    legacy: 37,
    offset: '+03:00',
    iana: ['Asia/Baghdad'],
  },
  BakuTbilisiYerevan: {
    legacy: 31,
    offset: '+04:00',
    iana: ['Asia/Baku', 'Asia/Tbilisi', 'Asia/Yerevan'],
  },
  BangkokHanoiJakarta: {
    legacy: 21,
    offset: '+07:00',
    // Hanoi is missing in iana-tz-data, use Asia/Ho_Chi_Minh for Hanoi (Vietnam)
    iana: ['Asia/Bangkok', 'Asia/Ho_Chi_Minh', 'Asia/Jakarta'],
  },
  BeijingChongqingHongKongUrumqi: {
    legacy: 19,
    offset: '+08:00',
    // Beijing is missing in iana-tz-data, use PRC for Beijing (China)
    // Asia/Urumqi has UTC offset +06:00/+06:00
    iana: ['PRC', 'Asia/Chongqing', 'Asia/Hong_Kong', 'Asia/Urumqi'],
  },
  BelgradeBratislavaBudapestLjubljanaPrague: {
    legacy: 47,
    offset: '+01:00',
    // All these iana time zones have UTC offset +01:00/+02:00
    iana: ['Europe/Belgrade', 'Europe/Bratislava', 'Europe/Budapest', 'Europe/Ljubljana', 'Europe/Prague'],
  },
  BogotaLimaQuito: {
    legacy: 61,
    offset: '-05:00',
    // Quito is missing in iana-tz-data, use America/Guayaquil for Quito (Ecuador)
    iana: ['America/Bogota', 'America/Lima', 'America/Guayaquil'],
  },
  Brasilia: {
    legacy: 54,
    offset: '-03:00',
    // Brasília is missing in iana-tz-data, use Brazil/East for Brasília (Brazil)
    // Brazil has several time zones, see: https://en.wikipedia.org/wiki/Time_in_Brazil
    // Brazil/East has UTC offset -03:00/-02:00
    iana: ['Brazil/East'],
  },
  Brisbane: {
    legacy: 9,
    offset: '+10:00',
    iana: ['Australia/Brisbane'],
  },
  BrusselsCopenhagenMadridParis: {
    legacy: 46,
    offset: '+01:00',
    // All these iana time zones have UTC offset +01:00/+02:00
    iana: ['Europe/Brussels', 'Europe/Copenhagen', 'Europe/Madrid', 'Europe/Paris'],
  },
  Bucharest: {
    legacy: 42,
    offset: '+02:00',
    // Europe/Bucharest has UTC offset +02:00/+03:00
    iana: ['Europe/Bucharest'],
  },
  BuenosAiresGeorgetown: {
    legacy: 55,
    offset: '-03:00',
    // Georgetown is missing in iana-tz-data, use America/Guyana for Georgetown (Guyana)
    // America/Guyana has UTC offset -04:00/-04:00
    iana: ['America/Buenos_Aires', 'America/Guyana'],
  },
  Cairo: {
    legacy: 41,
    offset: '+02:00',
    iana: ['Africa/Cairo'],
  },
  CanberraMelbourneSydney: {
    legacy: 8,
    offset: '+10:00',
    // All these iana time zones have UTC offset +10:00/+11:00
    iana: ['Australia/Canberra', 'Australia/Melbourne', 'Australia/Sydney'],
  },
  CapeVerdeIsland: {
    legacy: 52,
    offset: '-01:00',
    iana: ['Atlantic/Cape_Verde'],
  },
  CaracasLaPaz: {
    legacy: 59,
    offset: '-04:00',
    iana: ['America/Caracas', 'America/La_Paz'],
  },
  CasablancaMonrovia: {
    legacy: 49,
    offset: '+00:00',
    // Africa/Casablanca has UTC offset +00:00/+01:00
    iana: ['Africa/Casablanca', 'Africa/Monrovia'],
  },
  CentralAmerica: {
    legacy: 64,
    offset: '-06:00',
    // US/Central has UTC offset -06:00/-05:00
    iana: ['US/Central'],
  },
  CentralTimeUSCanada: {
    legacy: 65,
    offset: '-06:00',
    // US/Central is duplicated with CentralAmerica
    // Both these iana time zones have UTC offset -06:00/-05:00
    iana: ['US/Central', 'Canada/Central'],
  },
  ChennaiKolkataMumbaiNewDelhi: {
    legacy: 27,
    offset: '+05:30',
    // India has only one time zone
    // see: https://en.wikipedia.org/wiki/Time_in_India
    iana: ['Asia/Kolkata'],
  },
  ChihuahuaLaPazMazatlan: {
    legacy: 69,
    offset: '-07:00',
    // LaPaz alrady covered by CaracasLaPaz
    // America/La_Paz has UTC offset -04:00/-04:00
    // other 2 have UTC offset -07:00/-06:00
    iana: ['America/Chihuahua', 'America/La_Paz', 'America/Mazatlan'],
  },
  Darwin: {
    legacy: 10,
    offset: '+09:30',
    iana: ['Australia/Darwin'],
  },
  EasternTimeUSCanada: {
    legacy: 62,
    offset: '-05:00',
    // Both these iana time zones have UTC offset -05:00/-04:00
    iana: ['US/Eastern', 'Canada/Eastern'],
  },
  Ekaterinburg: {
    legacy: 29,
    offset: '+05:00',
    // Ekaterinburg should be misspelled Yekaterinburg
    iana: ['Asia/Yekaterinburg'],
  },
  FijiKamchatkaMarshallIsland: {
    legacy: 2,
    offset: '+12:00',
    // Marshall Islands is missing in iana-tz-data, use Pacific/Majuro for Marshall Islands
    // Pacific/Fiji has UTC offset +12:00/+13:00
    iana: ['Pacific/Fiji', 'Asia/Kamchatka', 'Pacific/Majuro'],
  },
  Greenland: {
    legacy: 56,
    offset: '-03:00',
    // Greenland is missing in iana-tz-data, use America/Godthab for Greenland
    // America/Godthab has UTC offset -03:00/-02:00
    iana: ['America/Godthab'],
  },
  GreenwichMeanTimeDublinEdinburghLisbonLondon: {
    legacy: 50,
    offset: '+00:00',
    // Edinburgh is missing in iana-tz-data, use GB for Edinburgh (Great British)
    // Europe/Dublin, GB, Europe/Lisbon and Europe/London have UTC offset +00:00/+01:00
    iana: ['GMT', 'Europe/Dublin', 'GB', 'Europe/Lisbon', 'Europe/London'],
  },
  GuadalajaraMexicoCityMonterrey: {
    legacy: 66,
    offset: '-06:00',
    // Guadalajara is missing in iana-tz-data, use Mexico/General for Guadalajara (Mexico)
    // All these iana time zones have UTC offset -06:00/-05:00
    iana: ['Mexico/General', 'America/Mexico_City', 'America/Monterrey'],
  },
  GuamPortMoresby: {
    legacy: 7,
    offset: '+10:00',
    iana: ['Pacific/Guam', 'Pacific/Port_Moresby'],
  },
  HararePretoria: {
    legacy: 40,
    offset: '+02:00',
    // Pretoria is missing in iana-tz-data, use Africa/Johannesburg for Pretoria (South Africa)
    iana: ['Africa/Harare', 'Africa/Johannesburg'],
  },
  Hawaii: {
    legacy: 73,
    offset: '-10:00',
    iana: ['US/Hawaii'],
  },
  HelsinkiKyivRigaSofiaTallinnVilnius: {
    legacy: 39,
    offset: '+02:00',
    // Kyiv is also translated as Kiev
    // All these iana time zones have UTC offset +02:00/+03:00
    iana: ['Europe/Helsinki', 'Europe/Kiev', 'Europe/Riga', 'Europe/Sofia', 'Europe/Tallinn', 'Europe/Vilnius'],
  },
  Hobart: {
    legacy: 6,
    offset: '+10:00',
    // Australia/Hobart has UTC offset +10:00/+11:00
    iana: ['Australia/Hobart'],
  },
  IndianaEast: {
    legacy: 63,
    offset: '-05:00',
    // US/East-Indiana has UTC offset -05:00/-04:00
    iana: ['US/East-Indiana'],
  },
  InternationalDateLineWest: {
    legacy: 75,
    offset: '-12:00',
    iana: ['Etc/GMT+12'],
  },
  IrkutskUlaanBataar: {
    legacy: 18,
    offset: '+08:00',
    iana: ['Asia/Irkutsk', 'Asia/Ulaanbaatar'],
  },
  IslandamabadKarachiTashkent: {
    legacy: 28,
    offset: '+05:00',
    // Islandamabad is misspelled Islamabad
    // Islamabad is missing in iana-tz-data, use Etc/GMT-5 for Islamabad (Pakistan)
    iana: ['Etc/GMT-5', 'Asia/Karachi', 'Asia/Tashkent'],
  },
  Jerusalem: {
    legacy: 38,
    offset: '+02:00',
    // Asia/Jerusalem has UTC offset +02:00/+03:00
    iana: ['Asia/Jerusalem'],
  },
  Kabul: {
    legacy: 30,
    offset: '+04:30',
    iana: ['Asia/Kabul'],
  },
  Kathmandu: {
    legacy: 26,
    offset: '+05:45',
    iana: ['Asia/Kathmandu'],
  },
  Krasnoyarsk: {
    legacy: 20,
    offset: '+07:00',
    iana: ['Asia/Krasnoyarsk'],
  },
  KualaLumpurSingapore: {
    legacy: 17,
    offset: '+08:00',
    iana: ['Asia/Kuala_Lumpur', 'Asia/Singapore'],
  },
  KuwaitRiyadh: {
    legacy: 36,
    offset: '+03:00',
    iana: ['Asia/Kuwait', 'Asia/Riyadh'],
  },
  MagadanSolomonIslandNewCaledonia: {
    legacy: 4,
    offset: '+11:00',
    // Solomon Islands is missing in iana-tz-data, use Pacific/Guadalcanal for Solomon Islands
    // New Caledonia is missing in iana-tz-data, use Pacific/Noumea for New Caledonia
    iana: ['Asia/Magadan', 'Pacific/Guadalcanal', 'Pacific/Noumea'],
  },
  MidAtlantic: {
    legacy: 53,
    offset: '-02:00',
    iana: ['America/Noronha'],
  },
  MidwayIslandand_Samoa: {
    legacy: 74,
    offset: '-11:00',
    iana: ['Pacific/Midway', 'Pacific/Samoa'],
  },
  MoscowStPetersburgVolgograd: {
    legacy: 35,
    offset: '+03:00',
    // Saint Petersburg is missing in iana-tz-data, use W-SU for Saint Petersburg (Russia)
    iana: ['Europe/Moscow', 'W-SU', 'Europe/Volgograd'],
  },
  MountainTime_US_Canada: {
    legacy: 70,
    offset: '-07:00',
    // Both these iana time zones have UTC offset -07:00/-06:00
    iana: ['US/Mountain', 'Canada/Mountain'],
  },
  Nairobi: {
    legacy: 34,
    offset: '+03:00',
    iana: ['Africa/Nairobi'],
  },
  Newfoundland: {
    legacy: 57,
    offset: '-03:30',
    // Canada/Newfoundland has UTC offset -03:30/-02:30
    iana: ['Canada/Newfoundland'],
  },
  None: {
    legacy: 0,
    offset: '+00:00',
    iana: ['UTC'],
  },
  Nukualofa: {
    legacy: 1,
    offset: '+13:00',
    // Nukuʻalofa is missing in iana-tz-data, use Pacific/Tongatapu for Nukuʻalofa (Tonga)
    // Pacific/Tongatapu has UTC offset +13:00/+14:00
    iana: ['Pacific/Tongatapu'],
  },
  OsakaSapporoTokyo: {
    legacy: 14,
    offset: '+09:00',
    // Japan uses same time zone in mainland
    iana: ['Japan'],
  },
  PacificTimeUSCanadaTijuana: {
    legacy: 71,
    offset: '-08:00',
    // All these iana time zones have UTC offset -08:00/-07:00
    iana: ['US/Pacific', 'Canada/Pacific', 'America/Tijuana'],
  },
  Perth: {
    legacy: 16,
    offset: '+08:00',
    iana: ['Australia/Perth'],
  },
  Rangoon: {
    legacy: 22,
    offset: '+06:30',
    iana: ['Asia/Rangoon'],
  },
  Santiago: {
    legacy: 60,
    offset: '-03:00',
    // Pacific/Tongatapu has UTC offset -04:00/-03:00
    iana: ['America/Santiago'],
  },
  SarajevoSkopjeWarsawZagreb: {
    legacy: 45,
    offset: '+01:00',
    // All these iana time zones have UTC offset +01:00/+02:00
    iana: ['Europe/Sarajevo', 'Europe/Skopje', 'Europe/Warsaw', 'Europe/Zagreb'],
  },
  Saskatchewan: {
    legacy: 67,
    offset: '-06:00',
    iana: ['Canada/Saskatchewan'],
  },
  Seoul: {
    legacy: 13,
    offset: '+09:00',
    iana: ['Asia/Seoul'],
  },
  SriJayawardenepura: {
    legacy: 23,
    offset: '+06:00',
    // Sri Jayawardenepura Kotte is missing in iana-tz-data
    // use Asia/Colombo for Sri Jayawardenepura Kotte (Sri Lanka)
    // Asia/Colombo has UTC offset +05:30/+05:30
    iana: ['Asia/Colombo'],
  },
  Taipei: {
    legacy: 15,
    offset: '+08:00',
    iana: ['Asia/Taipei'],
  },
  Tehran: {
    legacy: 33,
    offset: '+03:30',
    // Asia/Tehran has UTC offset +03:30/+04:30
    iana: ['Asia/Tehran'],
  },
  Vladivostok: {
    legacy: 5,
    offset: '+10:00',
    iana: ['Asia/Vladivostok'],
  },
  WestCentralAfrica: {
    legacy: 44,
    offset: '+01:00',
    // West Central Africa is missing in iana-tz-data, use Africa/Lagos for West Central Africa
    // see https://www.timeanddate.com/time/map/
    iana: ['Africa/Lagos'],
  },
  Yakutsk: {
    legacy: 12,
    offset: '+09:00',
    iana: ['Asia/Yakutsk'],
  },
};
