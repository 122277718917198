// Terms
// TLRU       - Time aware Least Recently Used
// LRU record - least recently used cache record
// LLT record - least life time cache record
// LRU chain  - a chain of all cache records in access sequence. The head of the
//              chain is the LRU record
// TTL heap   - a min-heap of all cache records according to their time to live.
//              the root of the heap is the LLT record

import { ReplacementStrategy } from './base';
import { EXP_INVALIDATED, DEFAULT_LIMIT } from '../constants';

function validateLimit(limit) {
  if (typeof limit !== 'number' || Math.floor(limit) !== limit || limit <= 0) {
    throw new Error('Invalid limit');
  }
}

/**
 * The record for 1 cached key value pair
 * @typedef {Object} LRURecord
 * @property {string} key - The key for the object cached in the record
 * @property {number} exp - The expiration time
 * @property {LRURecord} next - Next record in LRU chain (more recently used)
 * @property {LRURecord} prev - Previous record in LRU chain (less recenlty used)
 */

/* eslint-disable no-param-reassign */
/**
 * Remove an LRU record from the chain
 * @private
 * @param {LRURecord} record - The record to remove
 * @returns {void}
 */
function removeLRURecord(record) {
  record.prev.next = record.next;
  record.next.prev = record.prev;
}

/**
 * Insert an LRU record into the chain before the anchor record
 * @private
 * @param {LRURecord} record - The record to insert
 * @param {LRURecord} anchor - The anchor record
 * @returns {void}
 */
function insertLRURecord(record, anchor) {
  anchor.prev.next = record;
  record.prev = anchor.prev;
  anchor.prev = record;
  record.next = anchor;
}
/* eslit-enable no-param-reassign */

/**
 * Promote an LRU record in the chain, mark it as most recently used
 * @private
 * @param {Object} lru - The TLRU cache state
 * @param {LRURecord} lru.records - The LRU records
 * @param {Object} lru.indices -
 *    The index hash, a mapping from cache key to record index
 * @param {string} key - The key of the record to be promoted
 * @returns {void}
 */
function promoteLRURecord(lru, key) {
  const { records, indices } = lru;
  const record = records[indices[key]];
  const pseudoRecord = records[0];

  if (record !== pseudoRecord && record !== pseudoRecord.prev) {
    removeLRURecord(record);
    insertLRURecord(record, pseudoRecord);
  }
}

/* eslint-disable no-bitwise, no-continue */

/**
 * Adjust the LRU records
 * @private
 * @param {Object} lru - The TLRU cache state
 * @param {LRURecord} lru.records - The LRU records
 * @param {Object} lru.indices - The mapping from cache key to record index
 * @param {string} key - The key of the record to be promoted
 * @returns {void}
 */
function adjustHeap(lru, key) {
  const { records, indices } = lru;
  let index = indices[key];

  while (index > 0 && index < records.length) {
    const record = records[index];

    const indexParent = index >> 1;
    const recordParent = indexParent > 0 ? records[indexParent] : null;

    // Shift up the record if it has less life time than parent
    if (recordParent && recordParent.exp > record.exp) {
      indices[record.key] = indexParent;
      indices[recordParent.key] = index;
      records[index] = recordParent;
      records[indexParent] = record;
      index = indexParent;
      continue;
    }

    // Choose the child with less life time
    const indexL = index << 1;
    const indexR = indexL + 1;
    const recordL = records[indexL] || null;
    const recordR = records[indexR] || null;
    const indexChild = (recordR && recordR.exp < recordL.exp) ? indexR : indexL;
    const recordChild = records[indexChild] || null;

    // Shift down the record if it has more life time than the child
    if (recordChild && recordChild.exp < record.exp) {
      indices[record.key] = indexChild;
      indices[recordChild.key] = index;
      records[index] = recordChild;
      records[indexChild] = record;
      index = indexChild;
      continue;
    }

    break;
  }
}
/* eslint-disable no-continue */

export class LRUReplacementStrategy extends ReplacementStrategy {
  /**
   * The Time aware Least Recently Used cache replacement strategy.
   * The replacement rules are
   *  1. If the LLT cache entry is expired, reuse it
   *  2. If the cache is not full, create a new entry
   *  3. Reuse the LRU cache entry
   * @extends ReplacementStrategy
   * @param {Object} options -
   * @param {number} options.limit - Maximum number of cache entries
   */
  constructor({ limit = DEFAULT_LIMIT } = {}) {
    super();
    validateLimit(limit);

    this.limit = limit;
  }

  /**
   * Initialize the data structure to apply the strategy on a given cache
   * @override
   * @param {TimeAwareCache} cache - The cache to work with
   * @returns {void}
   */
  initializeCache(cache) {
    super.initializeCache(cache);

    // The pseudoRecord, doesn't hold any data, the purpose is
    // 1. Hold the head and tail of the LRU chain. Make the linked list a ring
    //    to simplify the logic.
    // 2. Take the index 0 of the TTL heap, to simplify the calculation of the
    //    index of the parent and the children in the heap.
    const pseudoRecord = {};
    pseudoRecord.next = pseudoRecord;
    pseudoRecord.prev = pseudoRecord;

    cache.lru = {
      // The TTL heap of the records, while the `next`, `prev` of the records
      // forms the LRU chain
      records: [pseudoRecord],

      // A map from the key to index in the TTL heap
      indices: {},
    };
  }

  /**
   * Get the key of the cache entry to replace, the rules are
   *  1. If the LLT entry is expired, reused it (return key of the LLT entry)
   *  2. If the cache is not full, allocate a new entry (return null)
   *  3. Reuse the LRU entry (return key of the LRU entry)
   * @override
   * @param {TimeAwareCache} cache - The cache to write with
   * @param {string} key - The key for the new cache entry
   * @param {number} timestamp - The timestamp of the write operation
   * @returns {string} - The key of the entry to replace
   */
  keyToReplace(cache, key, timestamp) {
    const { records, indices } = cache.lru;

    // Reuse the existing entry if the LRU record exists
    if (indices[key]) {
      return key;
    }

    // Reuse the LLT entry if it's already expired
    const recordLLT = records[1] || null;

    if (recordLLT && recordLLT.exp <= timestamp) {
      return recordLLT.key;
    }

    // Allocate a new entry if the cache is not full
    if (records.length <= this.limit) {
      return null;
    }

    // Reuse the LRU entry
    return records[0].next.key;
  }

  /**
   * Update the LRU records onGet
   * @override
   * @param {TimeAwareCache} cache - The cache being accessed
   * @param {number} timestamp - The timestamp of the operation
   * @param {CacheEntry} entry - The cache entry being read, null on cache miss
   * @returns {void}
   */
  onGet(cache, timestamp, { key, value, exp }) {
    if (value !== null && exp > timestamp) {
      promoteLRURecord(cache.lru, key);
    }
  }

  /**
   * Update the LRU records onSet
   * @override
   * @param {TimeAwareCache} cache - The cache being accessed
   * @param {number} timestamp - The timestamp of the operation
   * @param {CacheEntry} entry - The cache entry being written
   * @param {string} keyToReplace - The key of the replaced cache entry
   * @returns {void}
   */
  onSet(cache, timestamp, entry, keyToReplace) {
    const { records, indices } = cache.lru;

    if (keyToReplace) {
      const index = indices[keyToReplace];
      const record = records[index];

      record.key = entry.key;
      record.exp = entry.exp;

      delete indices[keyToReplace];
      indices[entry.key] = index;

      promoteLRURecord(cache.lru, entry.key);
    } else {
      const index = records.length;
      const record = { key: entry.key, exp: entry.exp };

      indices[entry.key] = index;
      records.push(record);
      insertLRURecord(record, records[0]);
    }

    adjustHeap(cache.lru, entry.key);
  }

  /**
   * Update the LRU records onGet
   * @override
   * @param {TimeAwareCache} cache - The cache being accessed
   * @param {number} timestamp - The timestamp of the operation
   * @param {CacheEntry} entry - The cache entry to delete, null on cache miss
   * @returns {void}
   */
  onDel(cache, timestamp, { key, value, exp }) {
    if (value !== null && exp > EXP_INVALIDATED) {
      const { records, indices } = cache.lru;
      const index = indices[key];
      const record = records[index];

      record.exp = EXP_INVALIDATED;
      adjustHeap(cache.lru, key);
    }
  }
}
