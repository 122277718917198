/* eslint camelcase: 0 */
import _ from 'underscore';
import { get } from '@bingads-webui-universal/primitive-utilities';
import timeZoneMetadata from '../metadata';

/**
 * Convert legacy Number type time zone (Enum value) to String type time zone (Enum name)
 * @param {Number} legacyTimeZone - the legacy time zone used in JS code
 * @returns {String} String type time zone
 */
export function fromLegacyTimeZone(legacyTimeZone) {
  return _.findKey(timeZoneMetadata, ({ legacy }) => legacy === legacyTimeZone);
}

/**
 * Convert String type time zone (Enum name) to legacy Number type time zone (Enum value)
 * @param {String} timeZone - the String type time zone
 * @returns {Number} Number type time zone
 */
export function toLegacyTimeZone(timeZone) {
  return get(timeZoneMetadata[timeZone], 'legacy');
}

/**
 * Convert String type time zone to IANA tz data time zone used in @bingads-webui/i18n-model
 * @param {String} timeZone - the String type time zone
 * @returns {String} String type time zone
 */
export function toIANATimeZone(timeZone) {
  // only return first IANA time zone, leave other ones for future usage
  return get(timeZoneMetadata[timeZone], 'iana[0]');
}

/**
 * Get String type time zone's UTC offset
 * This offset is defined by BingAdsApps, which doesn't consider Daylight Saving Time
 * @param {String} timeZone - the String type time zone
 * @returns {String} Readable UTC offset
 */
export function getUTCOffsetWithoutDST(timeZone) {
  return get(timeZoneMetadata[timeZone], 'offset');
}

/**
 * Get all supported time zones
 * @returns {String[]} all time zones
 */
export const allTimeZones = _.keys(timeZoneMetadata);
