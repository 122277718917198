/**
 * The base cache replacement strategy.
 */
export class ReplacementStrategy {
  /**
   * Get the key of the cache entry to replace
   * @param {TimeAwareCache} cache - The cache to write with
   * @param {string} key - The key for the new cache entry
   * @param {number} timestamp - The timestamp of the write operation
   * @returns {string} - The key of the entry to replace
   */
  keyToReplace(/* cache, key, timestamp */) { return null; }

  /**
   * Initialize the data structure to apply the strategy on a given cache
   * @param {TimeAwareCache} cache - The cache to work with
   * @returns {void}
   */
  initializeCache(cache) {
    cache.subscribe(this);
  }
}
