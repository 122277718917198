import _ from 'underscore';

const noMatch = /(.)^/;
const settings = {
  escape: noMatch,
  evaluate: noMatch,
  interpolate: /\{\{(.+?)\}\}/g,
};

/**
 * Passes constant double curly brace settings to the underscore template function.
 * E.g. to support templates of the format: 'The bid is {{bidAmount}}'
 *
 * @see {@link http://underscorejs.org/#template}
 *
 * @param {String} templateString - The template markup that contains double curly bracket notation.
 * @return {Function} The template function, which can be passed an Object model with fields that
 *    correspond to the names marked up by the curly braces.
 */
export function curlyTemplate(templateString: string): (...data: any[]) => string {
  return _.template(templateString, settings);
}

/**
 * check whether this string include CJK charact
 * @param {string} str - string to check
 * @returns {boolean} - return true if str contains CJK, otherwise, return false
 */
export function hasCJK(str: string): boolean {
  return !!(_.isString(str) && str.match(/[\u4E00-\u9FCC]/));
}

/**
 * Regex Pattern for wide characters
 */
const wideCharPattern = new RegExp(
  '[\\u1100-\\u11FF]' // IsHangulJamo
  + '|[\\u2E80-\\u2EFF]' // IsCJKRadicalsSupplement
  + '|[\\u3000-\\u303F]' // IsCJKSymbolsandPunctuation
  + '|[\\u3040-\\u309F]' // IsHiragana
  + '|[\\u30A0-\\u30FF]' // IsKatakana
  + '|[\\u3130-\\u318F]' // IsHangulCompatibilityJamo
  + '|[\\u3200-\\u32FF]' // IsEnclosedCJKLettersandMonths
  + '|[\\u3300-\\u33FF]' // IsCJKCompatibility
  + '|[\\u3400-\\u4DBF]' // IsCJKUnifiedIdeographsExtensionA
  + '|[\\u4E00-\\u9FFF]' // IsCJKUnifiedIdeographs
  + '|[\\uAC00-\\uD7AF]' // IsHangulSyllables
  + '|[\\uF900-\\uFAFF]' // IsCJKCompatibilityIdeographs
  + '|[\\uFE30-\\uFE4F]' // IsCJKCompatibilityForms
  + '|[\\uFF01-\\uFF60]' // Fullwidth Forms Part 1
  + '|[\\uFFE0-\\uFFE6]' // Fullwidth Forms Part 2
  + '|[\\uD800-\\uDBFF][\\uDC00-\\uDFFF]', // Astral Planes
  'g',
);

/**
 * Get string length, which counting CJK character or Emojis as 2
 * @param {string} str - string to count
 * @returns {int} - length of string
 */
export function displayLength(str: string | null) {
  return _.isString(str) ? str.replace(wideCharPattern, '12').length : 0;
}

export function getLength(str: string, distinguishWideChar = false) {
  return distinguishWideChar ? displayLength(str) : _.size(str);
}

// eslint-disable-next-line no-control-regex
const IsControlRegex = /[\u0000-\u001F\u007F-\u009F]/;

/**
 * Validate if string contains controlled characters, http://unicode.org/glossary/#control_codes
 * @param {String} str - String to be validated
 * @returns {Boolean} - True if string contains controlled char.
 */
export const isControl = (str: string) => IsControlRegex.test(str);
