// TODO: Task 904718: refactor to use explicit dependency for es6 syntax rather than global variable
import _ from 'underscore';

type Path = string | string[];

function castPath(value: Path, object: any) {
  if (_.isArray(value)) {
    return value;
  }

  if (_.has(object, value)) {
    return [value];
  }

  return _.compact(value.split(/[[\].]/));
}

function clone<T extends TProp, TProp>(item: T, sourceCopyMap: Map<TProp, TProp>): T {
  if (!_.isObject(item) || _.isDate(item) || _.isFunction(item)) {
    return item;
  }
  if (!sourceCopyMap.get(item)) {
    const result: T = <T>(_.isArray(item) ? [] : {});
    sourceCopyMap.set(item, result);
    _.each(<_.Dictionary<TProp>><unknown>item, (value, key) => {
      (<_.Dictionary<TProp>><unknown>result)[key] = clone(value, sourceCopyMap);
    });
  }
  return <T>sourceCopyMap.get(item);
}

/**
 * defined in lodash 4.5.1
 * sum list with iteratee
 * @param {Object[]} list - array to be summed
 * @param {Function} invoke - function invoked per element
 * @returns {number} - sum value of list
 */
export function sumBy<T>(list: T[], invoke: string | object | ((item: T, index: string | number) => number)): number {
  let iteratee: any;

  // make separate calls to satisfy @types/underscore for iteratee
  if (invoke instanceof String) {
    iteratee = _.iteratee(invoke);
  } else if (invoke instanceof Object) {
    iteratee = _.iteratee(invoke);
  } else {
    iteratee = _.iteratee(invoke);
  }

  return _.reduce(
    list,
    (currentSum, item, index, arr) => currentSum + iteratee(item, index, arr),
    0,
  );
}

/**
 * defined in lodash 0.5.0
 * merge two object from src to dest
 * @param {Object} dest - dest object
 * @param {Object} src - src object
 * @returns {Object} - merged object
 */
export function merge<T, TProp>(...args: T[]): T {
  return _.reduce(_.rest(args), (dest, src) => {
    _.each(<_.Dictionary<TProp>><unknown>src, (value, key) => {
      const destKey = (<_.Dictionary<TProp>><unknown>dest)[key];
      if (_.isObject(destKey) && _.isObject(value) && !_.isFunction(value)) {
        merge(destKey, value);
      } else {
        _.extend(dest, _.object([key], [value]));
      }
    });

    return dest;
  }, _.first(args) || <T>{});
}

/**
 * defined in lodash 4.6.1
 * Gets the value at path of object. If the resolved value is undefined,
 * the defaultValue is returned in its place.
 * @param {Object} object - The object to query.
 * @param {array|string} path - The path of the property to get.
 * @param {*} defaultValue - Optional, The value returned for undefined resolved values.
 * @returns {any} - return the value in object by path, if not exist, return default value
 */
export function get<T, TProp>(object: T, path: Path, defaultValue?: TProp): TProp | undefined {
  let sub: _.Dictionary<TProp> & TProp;
  let i;
  const keyPath = castPath(path, object);

  for (i = 0, sub = <_.Dictionary<TProp> & TProp><unknown>object; i < keyPath.length; i += 1) {
    if (!_.isObject(sub)) {
      return defaultValue;
    }

    sub = <_.Dictionary<TProp> & TProp>sub[keyPath[i]];

    if (_.isUndefined(sub)) {
      return defaultValue;
    }
  }

  return sub;
}

/**
 * lodash set, path don't support index
 * Sets the value at path of object. If a portion of path doesn't exist, it's created.
 * Arrays are created for missing index properties while
 * objects are created for all other missing properties.
 * @param {Object} object - The object to modify.
 * @param {string} path - The path of the property to set.
 * @param {*} value - The value to set.
 * @returns {Object} - Returns object.
 */
export function set<T, TProp>(object: T, path: Path, value: TProp): T {
  if (!_.isObject(object)) {
    return object;
  }

  const keyPath = castPath(path, object);
  let index = 0;
  const { length } = keyPath;
  const lastIndex = length - 1;
  let nested = <_.Dictionary<TProp> & TProp><unknown>object;

  while (nested != null && index < length) {
    const key = keyPath[index];

    if (index !== lastIndex) {
      (nested as _.Dictionary<TProp>)[key] = nested[key] || <TProp>{};
      nested = <_.Dictionary<TProp> & TProp>nested[key];
    } else {
      (nested as _.Dictionary<TProp>)[key] = value;
    }
    index += 1;
  }
  return object;
}

/**
 * Creates a function that returns the result of invoking the given
 * functions with the this binding of the created function,
 * where each successive invocation is supplied the return value of the previous.
 * lodash flow https://lodash.com/docs#flow
 * @param {function[]} funcs - The functions to invoke.
 * @returns {Function} - Returns the new composite function.
 */
export function flow(...funcs: Function[]): Function {
  return _.compose.apply(null, funcs.reverse());
}

/**
 * defined in lodash 4.0.0
 * Check whether value is undefined or null
 * @param {*} value - value to Check
 * @returns {boolean} - return true if value is undefined or null, otherwise, return false
 */
export function isNil(value: any): boolean {
  return _.isUndefined(value) || _.isNull(value);
}

/**
 * deep clone object
 * @param {array|Object} item - source item to be copied
 * @return {array|Object} - copied from item
*/
export function cloneDeep<T>(item: T): T {
  return clone(item, new Map());
}

function objIsArray(obj: any): obj is any[] {
  return _.isArray(obj) && Boolean(obj.length);
}

/**
 * array dedup function
 * @param {any[]} array - the array to inspect
 * @param {function} comparator - comparator function to determine whether 2 elements are the same
 * @returns {any[]} - new duplicate free array
 */
export function uniqWith<T>(array: any, comparator?: (arg1: T, arg2: T) => boolean): T[] {
  if (!objIsArray(array)) {
    return [];
  }
  if (!_.isFunction(comparator)) {
    return _.uniq(array);
  }
  const result: T[] = [];
  _.each(array, (obj) => {
    if (_.every(result, (res) => !comparator(obj, res))) {
      result.push(obj);
    }
  });
  return result;
}
