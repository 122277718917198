import { getLastMonth } from '../date-util';

export const ComparedType = {
  LastPeriod: 0,
  LastMonth: 1,
  LastYear: 2,
  Custom: 3,
};
export const ComparedOptions = [
  {
    type: ComparedType.LastPeriod,
    textToExport: 'Previous period',
    keyToExport: 'previousPeriod',
    get: (range, specificRangeId) => {
      switch (specificRangeId) {
        // this week
        case 7:
        case 8:
          return ({
            start: range.start.plus({ days: -7 }),
            end: range.end.plus({ days: -7 }),
          });
        // this month
        case 10:
          return ({
            start: getLastMonth(range.start),
            end: getLastMonth(range.end),
          });
        default: {
          const t2 = new Date(range.end.year, range.end.month - 1, range.end.day).getTime();
          const t1 = new Date(range.start.year, range.start.month - 1, range.start.day).getTime();
          // eslint-disable-next-line radix
          const difference = parseInt((t2 - t1) / (24 * 3600 * 1000));
          return ({
            start: range.start.plus({ days: -1 - difference }),
            end: range.start.plus({ days: -1 }),
          });
        }
      }
    },
  }, {
    type: ComparedType.LastMonth,
    textToExport: 'Last month',
    keyToExport: 'lastMonth',
    get: range => ({
      start: getLastMonth(range.start),
      end: getLastMonth(range.end),
    }),
  }, {
    type: ComparedType.LastYear,
    textToExport: 'Same period last year',
    keyToExport: 'samePeriodLastYear',
    get: range => ({
      start: range.start.plus({ years: -1 }),
      end: range.end.plus({ years: -1 }),
    }),
  }, {
    type: ComparedType.Custom,
    textToExport: 'Custom',
    keyToExport: 'customPeriod',
    get: () => ({
      start: undefined,
      end: undefined,
    }),
  },
];
