/* eslint-disable max-len */
import { CivilDate } from 'tc39-proposal-temporal';
import { fromLegacyTimeZone, toIANATimeZone } from '@bingads-webui-universal/time-zone-converter';

export const getLastMonth = (date) => {
  let lastMonthDate = date.plus({ months: -1 });
  if (lastMonthDate.day !== date.day) {
    lastMonthDate = lastMonthDate.plus({ days: -lastMonthDate.day });
  }
  return lastMonthDate;
};

export const formatCivilDate = ({
  i18n, date, dateFormat = 'M/d/yyyy',
}) => `${date ? i18n.formatCivilDate(date, { raw: dateFormat }) : ''}`;

export const getDateObj = ({ i18n, dateString, dateFormat }) => {
  if (!dateString) { return null; }
  if (dateFormat) { return i18n.parseCivilDate(dateString, { raw: dateFormat }); }

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return (year && month && day) ? {
    year,
    month,
    day,
  } : null;
};

export const parseCivilDate = ({ i18n, dateString, dateFormat }) => {
  const dateObj = getDateObj({ i18n, dateString, dateFormat });
  return dateObj ? new CivilDate(dateObj.year, dateObj.month, dateObj.day) : undefined;
};

export const translateLegacyToIANATimezone =
  legacyTimeZone => toIANATimeZone(fromLegacyTimeZone(legacyTimeZone));

export const generateDateData = (i18n, dateFromDatePicker, dateFormat, lcid) => {
  const formattedStart = dateFromDatePicker.selectedComparedRange ? formatCivilDate({ i18n, date: dateFromDatePicker.selectedComparedRange.start, dateFormat }) : null;
  const formattedEnd = dateFromDatePicker.selectedComparedRange ? formatCivilDate({ i18n, date: dateFromDatePicker.selectedComparedRange.end, dateFormat }) : null;
  let compared;
  if (!formattedStart && !formattedEnd) {
    compared = null;
  } else {
    compared = {
      formattedStart,
      formattedEnd,
      text: dateFromDatePicker.comparedOption && dateFromDatePicker.comparedOption.textToExport,
      key: dateFromDatePicker.comparedOption && dateFromDatePicker.comparedOption.keyToExport,
      start: i18n.parseDate(formattedStart),
      end: i18n.parseDate(formattedEnd),
    };
  }
  const date = {
    RangeStartDate: formatCivilDate({ i18n, date: dateFromDatePicker.selectedRange.start, dateFormat }),
    RangeEndDate: formatCivilDate({ i18n, date: dateFromDatePicker.selectedRange.end, dateFormat }),
    RangeId: dateFromDatePicker.rangeType.rangeId,
    Rangelcid: lcid,
    compared,
    RangeIndex: dateFromDatePicker.rangeIndex,
    RangeDays: dateFromDatePicker.rangeDays,
  };
  return date;
};

export const getRangesFromData = (i18n, dateData, dateFormat, lcid) => {
  if (!dateData || dateData.Rangelcid !== lcid) {
    return { selectedRange: undefined, selectedComparedRange: undefined };
  }
  const {
    RangeStartDate,
    RangeEndDate,
    compared,
  } = dateData;
  return {
    selectedRange: {
      start: RangeStartDate ? parseCivilDate({ i18n, dateString: RangeStartDate, dateFormat }) : undefined,
      end: RangeEndDate ? parseCivilDate({ i18n, dateString: RangeEndDate, dateFormat }) : undefined,
    },
    selectedComparedRange: {
      start: compared && compared.formattedStart ?
        parseCivilDate({ i18n, dateString: compared.formattedStart, dateFormat }) : undefined,
      end: compared && compared.formattedEnd ?
        parseCivilDate({ i18n, dateString: compared.formattedEnd, dateFormat }) : undefined,
    },
  };
};
