/* eslint-env browser */
import _ from 'underscore';

/*
* Returns a copy of the passed in Date with hours/minutes/seconds/milliseconds set to zero.
* @param {Date} date - Date to remove time information from
* @returns {Date} - Copy of passed in date
*/
export function withoutTime(date: any) {
  if (!date) {
    return date;
  }

  const returnDate = new Date(date.valueOf());
  returnDate.setHours(0, 0, 0, 0);
  return returnDate;
}

/**
 * Validates if the date range passed in is incorrect. It ignores the time values.
 * @param { Date } [minDate] - The minimum date that can be selected.
 * @param { Date } [maxDate] - The maximum date that can be selected.
 * @param { Date } [selectedDate] - The date to pre-select in the datepicker.
 * @returns {String[]} - Array of error strings.
 * @throws Will throw if passed in date range is not valid.
 */
export function validateDateRange(minDate: any, maxDate: any, selectedDate: any): string[] {
  const selected = withoutTime(selectedDate);
  const min = withoutTime(minDate);
  const max = withoutTime(maxDate);

  const errors = [];

  if (min && max && min > max) {
    errors.push('MinDateLaterThanMaxDate');
  }

  if (selected && min && selected < min) {
    errors.push('MinDateLaterThanSelectedDate');
  }

  if (selected && max && selected > max) {
    errors.push('SelectedDateLaterThanMaxDate');
  }

  return errors;
}

/**
 * Private function to check if date is valid
 * @param {Object} date - Object to check
 * @returns {Boolean} - True if object is a valid date
 * @private
*/
function isValidDate(date: object): date is Date {
  return _.isDate(date);
}

/**
 * Get the date N days ago
 * @param {Date} dateTime - The input date object
 * @param {Number} [lookBackDays=0] - Number of days to remove
 * @returns {Date} - date object with new parameters, null if date is invalid
 */
export function lastNDate(dateTime: any, lookBackDays: number = 0): Date | null {
  return isValidDate(dateTime)
    ? new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate() - lookBackDays) : null;
}

/**
 * Get first date of the month N months ago
 * @param {Date} dateTime - The input date object
 * @param {Number} [lookBackMonths=0] - Number of months to remove
 * @returns {Date} - Date object with new parameters
 */
export function firstDateNMonthsAgo(dateTime: any, lookBackMonths: number = 0): Date | null {
  return isValidDate(dateTime)
    ? new Date(dateTime.getFullYear(), dateTime.getMonth() - lookBackMonths, 1) : null;
}

/**
 * Get last date of the month N months ago
 * @param {Date} dateTime - The input date object
 * @param {Number} [lookBackMonths=0] - Number of months to remove
 * @returns {Date} - Date object with new parameters
 */
export function lastDateNMonthsAgo(dateTime: any, lookBackMonths: number = 0): Date | null {
  return isValidDate(dateTime)
    ? lastNDate(<Date>firstDateNMonthsAgo(dateTime, lookBackMonths - 1), 1) : null;
}

/**
 * Get today for N years ago
 * @param {Date} dateTime - The input date object
 * @param {Number} [lookBackYears=0] - Number of years to remove
 * @returns {Date} - Date object with new parameters
 */
export function todayNYearsAgo(dateTime: any, lookBackYears: number = 0): Date | null {
  return isValidDate(dateTime) ? new Date(
    dateTime.getFullYear() - lookBackYears,
    dateTime.getMonth(),
    dateTime.getDate(),
  ) : null;
}

/**
 * Get first date of the year N years ago
 * @param {Date} dateTime - The input date object
 * @param {Number} [lookBackYears=0] - Number of years to remove
 * @returns {Date} - Date object with new parameters
 */
export function firstDateNYearsAgo(dateTime: Date, lookBackYears: number = 0): Date | null {
  return isValidDate(dateTime) ? new Date(dateTime.getFullYear() - lookBackYears, 0, 1) : null;
}

/**
 * Get the current UTC time
 * @returns {String} - the current utc datetime
 */
export function getUtcTime(): string {
  return new Date().toJSON();
}

/**
 * Get the current timestamp
 * @returns {number} - the timestamp since epoch in milliseconds
 */
export function getTimestamp(): number {
  // TODO: this method should not be here, as it depends on Web API performance
  if (window.performance && window.performance.now && window.performance.timing) {
    return window.performance.timing.navigationStart + window.performance.now();
  }

  return Date.now();
}

type ComparisonDateRange = {
  start: Date,
  end: Date
};

type DateRange = {
  start?: Date,
  end?: Date,
  key?: string,
  compared?: ComparisonDateRange | null
};

/**
 * Check if the given two date ranges equal
 * @param {Object} firstRange - The first input range
 * @param {Object} secondRange - The second input range
 * @returns {Boolean} - Two ranges equal
 */
export function dateRangeEquals(firstRange?: null | DateRange, secondRange?: null | DateRange): boolean {
  function convertForComparison(range: DateRange) {
    const dates = _.pick(range, 'start', 'end', 'key');

    if (range.compared) {
      _.extend(dates, {
        comparedStart: range.compared.start,
        comparedEnd: range.compared.end,
      });
    }
    return dates;
  }

  if (!firstRange || !secondRange) {
    return false;
  }

  return JSON.stringify(convertForComparison(firstRange))
    === JSON.stringify(convertForComparison(secondRange));
}

type TimeZone = string;
type TimeZoneOption = TimeZone | null;
type DateMethodsOptions = { timeZone?: TimeZoneOption };

type I18nModel = {
  formatDate: (date: Date, options: DateMethodsOptions) => string,
  parseDate: (strDate: string, options: DateMethodsOptions) => Date | null,
};

export function convertDateBetweenTimeZones(date: Date, i18n: I18nModel, {
  from,
  to,
}: {
  from?: TimeZone | null,
  to?: TimeZone | null
} = {}) {
  const dateString = i18n.formatDate(date, {
    timeZone: from,
  });

  return i18n.parseDate(dateString, {
    timeZone: to,
  });
}

export function convertMachineDateToUTCDate(date: Date, i18n: any) {
  return convertDateBetweenTimeZones(date, i18n, { from: null, to: 'UTC' });
}

export function convertUTCDateToMachineDate(date: Date, i18n: any) {
  return convertDateBetweenTimeZones(date, i18n, { from: 'UTC', to: null });
}
