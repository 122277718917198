import Promise from 'bluebird';

export function promisifyObservable(observable) {
  return new Promise((resolve, reject) => {
    const subscription = observable.subscribe({
      next: (data) => {
        resolve(data);
        if (subscription) {
          subscription.unsubscribe();
        }
      },
      error: (err) => {
        reject(err);
        if (subscription) {
          subscription.unsubscribe();
        }
      },
    });
  });
}
