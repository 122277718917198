import { Subject } from '@bingads-webui-universal/observer-pattern';
import { defaultStrategy } from './replacement-strategy/index';
import { EXP_INVALIDATED } from './constants';

function validateTTL(ttl) {
  if (typeof ttl !== 'number' || ttl <= 0) {
    throw new Error('Invalid TTL');
  }
}

function validateKey(key) {
  if (typeof key !== 'string') {
    throw new Error('Invalid key');
  }
}

function validateValue(value) {
  if (typeof value === 'undefined' || value === null) {
    throw new Error('Invalid value');
  }
}

function stubEntry(key) {
  return { key, value: null, exp: EXP_INVALIDATED };
}

/**
 * Observer of cache read/write/delete events
 * @typedef {Object} CacheObserver
 * @property {CacheOnGetCallback} onGet - Callback on cache read operations
 * @property {CacheOnSetCallback} onSet - Callback on cache write operations
 * @property {CacheOnDelCallback} onDel - Callback on cache delete operations
 */

/**
 * Callback on cache read operations
 * @callback CacheOnGetCallback
 * @param {TimeAwareCache} cache - The cache being accessed
 * @param {number} timestamp - The timestamp of the operation
 * @param {CacheEntry} entry - The cache entry being read. On cache miss, it
 *    will be a fake entry with the accessing key and a null value.
 * @returns {void}
 */

/**
 * Callback on cache write operations
 * @callback CacheOnSetCallback
 * @param {TimeAwareCache} cache - The cache being accessed
 * @param {number} timestamp - The timestamp of the operation
 * @param {CacheEntry} entry - The cache entry being written
 * @param {string} keyToReplace - The key of the replaced cache entry
 * @returns {void}
 */

/**
 * Callback on cache delete operations
 * @callback CacheOnDelCallback
 * @param {TimeAwareCache} cache - The cache being accessed
 * @param {number} timestamp - The timestamp of the operation
 * @param {CacheEntry} entry - The cache entry to delete, null on cache miss
 * @returns {void}
 */

/**
 * A key-value cache with a TTL attached to each item
 */
export class TimeAwareCache extends Subject {
  /**
   * @param {Object} options -
   * @param {number} [options.ttl=Infinity] -
   *    The default TTL for items in this cache in milliseconds.
   * @param {CacheStrategy} [options.strategy=defaultStrategy] -
   *    The cache replacement strategy.
   *    Default to an instance of UnlimitedCacheStrategy.
   */
  constructor({
    ttl = Infinity,
    strategy = defaultStrategy,
  } = {}) {
    super();
    validateTTL(ttl);

    this.ttl = ttl;
    this.strategy = strategy;
    this.entries = {};

    strategy.initializeCache(this);
  }

  /**
   * Read value for given key from cache
   * @param {string} key - The key to access
   * @returns {Object} - The value associated with the key
   */
  get(key) {
    validateKey(key);

    const timestamp = Date.now();
    const entry = this.entries[key] || null;

    this.notify('onGet', timestamp, entry || stubEntry(key));
    return entry && entry.exp > timestamp ? entry.value : null;
  }

  /**
   * Write a key/value pair to cache
   * @param {string} key - The key of to write
   * @param {Object} value - The value to write
   * @param {number} ttl - The time for the key/value pair to live in milliseconds
   * @returns {void}
   */
  set(key, value, ttl = this.ttl) {
    validateKey(key);
    validateValue(value);
    validateTTL(ttl);

    const timestamp = Date.now();
    const entry = this.entries[key] || null;
    const keyToReplace = entry ? key : this.strategy.keyToReplace(this, key, timestamp);
    const entryNew = { key, value, exp: timestamp + ttl };

    if (keyToReplace && keyToReplace !== key) {
      delete this.entries[keyToReplace];
    }

    this.entries[key] = entryNew;

    this.notify('onSet', timestamp, entryNew, keyToReplace);
  }

  /**
   * Delete a key/value pair from cache
   * @param {string} key - The key to remove
   * @returns {void}
   */
  del(key) {
    validateKey(key);

    const timestamp = Date.now();
    const entry = this.entries[key] || null;

    if (entry) {
      delete this.entries[key];
    }

    this.notify('onDel', timestamp, entry || stubEntry(key));
  }

  /**
   * Purge all the expired entries
   * @returns {void}
   */
  purge() {
    const timestamp = Date.now();

    Object.keys(this.entries).forEach((key) => {
      if (this.entries[key].exp <= timestamp) {
        this.del(key);
      }
    });
  }
}
