import _ from 'underscore';
import { CivilDate } from 'tc39-proposal-temporal';

export const MAX_VALUE = 8640000000000000;
export const MIN_VALUE = -8640000000000000;

export function isDateInvalid(date) {
  const year = _.get(date, 'year');
  const month = _.get(date, 'month');
  const day = _.get(date, 'day');
  return _.isNaN(year) || _.isUndefined(year) || _.isNull(year) ||
    _.isNaN(month) || _.isUndefined(month) || _.isNull(month) ||
    _.isNaN(day) || _.isUndefined(day) || _.isNull(day);
}

// Returns a date that is the first day of the month of the provided date.
export function getCivilDateMonthStart(date) {
  return new CivilDate(date.year, date.month, 1);
}

// Returns a date that is the last day of the month of the provided date.
export function getCivilDateMonthEnd(date) {
  return new CivilDate(date.year, date.month + 1, 0);
}

// Checks whether the date is valid.
export const isValidDate = value => _.isDate(value) && !_.isNaN(value.getTime());

// Get a Civil Date from Date object
export const getCivilDateFromDate = value => (isValidDate(value) ? new CivilDate(
  value.getFullYear(),
  value.getMonth() + 1,
  value.getDate()
) : null);

export const maxCivilDate = getCivilDateFromDate(new Date(MAX_VALUE));
export const minCivilDate = getCivilDateFromDate(new Date(MIN_VALUE));

// Compare the month parts of two dates. A negative value if date1 is earlier than date2,
// 0 if the dates are equal, or a positive value if date1 is later than date2.
export function compareCivilMonth(date1, date2) {
  if (date1.year !== date2.year) {
    return date1.year - date2.year;
  }
  return date1.month - date2.month;
}

// Compare the date parts of two dates. A negative value if date1 is earlier than date2,
// 0 if the dates are equal, or a positive value if date1 is later than date2.
export function compareCivilDate(date1, date2) {
  if (date1.year !== date2.year) {
    return date1.year - date2.year;
  } else if (date1.month !== date2.month) {
    return date1.month - date2.month;
  }
  return date1.day - date2.day;
}

export function getMinDate(date1, date2) {
  if (isDateInvalid(date1)) {
    return date2;
  }
  if (isDateInvalid(date2)) {
    return date1;
  }
  if (compareCivilDate(date1, date2) <= 0) {
    return date1;
  }
  return date2;
}

export function getMaxDate(date1, date2) {
  if (isDateInvalid(date1)) {
    return date2;
  }
  if (isDateInvalid(date2)) {
    return date1;
  }
  if (compareCivilDate(date1, date2) >= 0) {
    return date1;
  }
  return date2;
}

// Checks whether the specified date is in the given date range.
export function isCivilDateInRange(date, { start, end }) {
  if (start && end) {
    if ((compareCivilDate(start, date) >= 0 && compareCivilDate(end, date) <= 0)
      || (compareCivilDate(start, date) <= 0 && compareCivilDate(end, date) >= 0)) {
      return true;
    }
  }
  return false;
}

// Checks whether the specified date is in the given date open range
// eslint-disable-next-line max-len
export const isCivilDateInOpenRange = ({ minDate = minCivilDate, maxDate = maxCivilDate, value }) => {
  if (!value) {
    return false;
  }
  return isCivilDateInRange(value, { start: minDate, end: maxDate });
};

// Checks whether the month of the specified date is in the given date range.
export function isCivilMonthInRange(date, { start, end }) {
  if (start && end) {
    return (compareCivilMonth(start, date) >= 0 && compareCivilMonth(end, date) <= 0)
    || (compareCivilMonth(start, date) <= 0 && compareCivilMonth(end, date) >= 0);
  }
  return false;
}

// TODO: remove it and use civildate own function to do this.
// There is a issue tracking this function: https://github.com/tc39/proposal-temporal/issues/69
export function getCivilDateDayOfWeek(civilDate) {
  return (new Date(civilDate.year, civilDate.month - 1, civilDate.day)).getDay();
}

// get a date that is the monday of this week(monday - sunday)
export const getThisMonday = (date, includeToday = true) => {
  if (isDateInvalid(date) || date.plus == null) {
    return null;
  }
  if (includeToday) {
    return (getCivilDateDayOfWeek(date) === 0 ?
      date.plus({ days: -6 }) :
      date.plus({ days: -getCivilDateDayOfWeek(date) + 1 }));
  }
  // map [1, 2, 3, 4, 5, 6, 0] to [7, 1, 2, 3, 4, 5, 6]
  const dayOfWeek = getCivilDateDayOfWeek(date);
  if (dayOfWeek === 1) {
    return date.plus({ days: -7 });
  }
  return date.plus({ days: -((dayOfWeek + 6) % 7) });
};

export function getCivilDateToday() {
  return getCivilDateFromDate(new Date());
}

// Checks whether the specified date is the the given date range start or end.
export function isCivilDateRangeStartOrEnd(date, dateRange) {
  return _.some(dateRange, d => compareCivilDate(date, d) === 0);
}

function withDateChecker(date, func) {
  if (isDateInvalid(date) || date.plus == null) {
    return null;
  }
  return func();
}

export function getThisSunday(date, includeToday = true) {
  // map [1, 2, 3, 4, 5, 6, 0] to [1, 2, 3, 4, 5, 6, 0]
  return withDateChecker(date, () => {
    const dayOfWeek = getCivilDateDayOfWeek(date);
    if (!includeToday && dayOfWeek === 0) {
      return date.plus({ days: -7 });
    }
    return date.plus({ days: -(dayOfWeek) });
  });
}

export function getThisSaturday(date, includeToday = true) {
  // map [1, 2, 3, 4, 5, 6, 0] to [2, 3, 4, 5, 6, 0, 1]
  return withDateChecker(date, () => {
    const dayOfWeek = getCivilDateDayOfWeek(date);
    if (!includeToday && dayOfWeek === 6) {
      return date.plus({ days: -7 });
    }
    return date.plus({ days: -((dayOfWeek + 1) % 7) });
  });
}

export function getLastMonday(date, includeToday = true) {
  // map [1, 2, 3, 4, 5, 6, 0] to [0, 1, 2, 3, 4, 5, 6]
  return withDateChecker(date, () => {
    const dayOfWeek = getCivilDateDayOfWeek(date);
    if (!includeToday && dayOfWeek === 1) {
      return date.plus({ days: -14 });
    }
    return date.plus({ days: -(((dayOfWeek + 6) % 7) + 7) });
  });
}

export function getLastSunday(date, includeToday = true) {
  return withDateChecker(date, () => {
    const dayOfWeek = getCivilDateDayOfWeek(date);
    if (!includeToday && dayOfWeek === 0) {
      return date.plus({ days: -14 });
    }
    return date.plus({ days: -(dayOfWeek + 7) });
  });
}

export function getNextMonday(date, includeToday = false) {
  return withDateChecker(date, () => {
    if (includeToday) {
      // map [1, 2, 3, 4, 5, 6, 0] to [0, 6, 5, 4, 3, 2, 1]
      const dayOfWeek = getCivilDateDayOfWeek(date);
      return date.plus({ days: (((7 - dayOfWeek) + 1) % 7) });
    }
    const dayOfWeek = getCivilDateDayOfWeek(date);
    return date.plus({ days: -((dayOfWeek + 6) % 7) + 7 });
  });
}

export function getNextSunday(date, includeToday = false) {
  return withDateChecker(date, () => {
    if (includeToday) {
      const dayOfWeek = getCivilDateDayOfWeek(date);
      return date.plus({ days: ((7 - dayOfWeek) % 7) });
    }
    const dayOfWeek = getCivilDateDayOfWeek(date);
    return date.plus({ days: -(dayOfWeek) + 7 });
  });
}

export function getNextSaturday(date, includeToday = false) {
  return withDateChecker(date, () => {
    if (includeToday) {
      // map [1, 2, 3, 4, 5, 6, 0] to [5, 4, 3, 2, 1, 0, 6]
      const dayOfWeek = getCivilDateDayOfWeek(date);
      return date.plus({ days: ((6 - dayOfWeek) % 7) });
    }
    const dayOfWeek = getCivilDateDayOfWeek(date);
    return date.plus({ days: -((dayOfWeek + 1) % 7) + 7 });
  });
}

export function getNextNextSunday(date, includeToday = false) {
  return withDateChecker(date, () => {
    const dayOfWeek = getCivilDateDayOfWeek(date);
    if (includeToday && dayOfWeek === 0) {
      return date.plus({ days: 7 });
    }
    return date.plus({ days: -(dayOfWeek) + 14 });
  });
}

export function getNextNextSaturday(date, includeToday = false) {
  return withDateChecker(date, () => {
    const dayOfWeek = getCivilDateDayOfWeek(date);
    if (includeToday && dayOfWeek === 6) {
      return date.plus({ days: 7 });
    }
    return date.plus({ days: -((dayOfWeek + 1) % 7) + 14 });
  });
}

export function isDateAvailable(date, minDate, maxDate) {
  if (isDateInvalid(date) || isDateInvalid(minDate) || isDateInvalid(maxDate)) {
    return false;
  }
  return isCivilDateInRange(date, { start: minDate, end: maxDate });
}

export function isRangeValid(range) {
  const start = _.get(range, 'start');
  const end = _.get(range, 'end');
  if (isDateInvalid(start) || isDateInvalid(end)) {
    return false;
  }
  return compareCivilDate(start, end) <= 0;
}

export function isRangeDateAvailable(range, minDate, maxDate) {
  if (!isRangeValid(range)) {
    return false;
  }
  const start = _.get(range, 'start');
  const end = _.get(range, 'end');
  return isDateAvailable(start, minDate, maxDate) && isDateAvailable(end, minDate, maxDate);
}

export function getDaysOfARange(range) {
  if (!isRangeValid(range)) {
    return 0;
  }
  const end = _.get(range, 'end');
  const start = _.get(range, 'start');
  const t2 = new Date(end.year, end.month - 1, end.day).getTime();
  const t1 = new Date(start.year, start.month - 1, start.day).getTime();
  // eslint-disable-next-line radix
  const difference = Math.round((t2 - t1) / (24 * 3600 * 1000));
  return difference + 1;
}

export function getMonthsOfARange(range) {
  if (!isRangeValid(range)) {
    return 0;
  }
  const end = _.get(range, 'end');
  const start = _.get(range, 'start');
  if (end.year === start.year) {
    return (end.month - start.month) + 1;
  }
  const fullYears = end.year - start.year - 1;
  return (fullYears * 12) + ((12 - start.month) + 1) + end.month;
}

export function isTheSameRange(range1, range2) {
  const start1 = _.get(range1, 'start');
  const start2 = _.get(range2, 'start');
  const end1 = _.get(range1, 'end');
  const end2 = _.get(range2, 'end');
  if (isDateInvalid(start1) || isDateInvalid(start2) || isDateInvalid(end1) || isDateInvalid(end2)) {
    return false;
  }
  return compareCivilDate(start1, start2) === 0 && compareCivilDate(end1, end2) === 0;
}
