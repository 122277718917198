class Cache {
  max: number;

  lruTrigger: number;

  cache: Map<any, any>;

  constructor(max: number = 200) {
    this.max = max;
    this.lruTrigger = max / 2;
    this.cache = new window.Map();
  }

  get(key: any) {
    const item = this.cache.get(key);
    if (this.cache.size > this.lruTrigger && item) {
      // refresh key
      this.cache.delete(key);
      this.cache.set(key, item);
    }
    return item;
  }

  set(key: any, val: any) {
    if (this.cache.size > this.lruTrigger) {
      // refresh key
      if (this.cache.has(key)) {
        this.cache.delete(key);
      } else if (this.cache.size === this.max) {
        this.cache.delete(this.first());
      }
    }
    this.cache.set(key, val);
  }

  first() {
    return this.cache.keys().next().value;
  }
}

function resolveFromCache(args: any[], index: number, cache: Cache, func:(...args: any[]) => any): any {
  const key = args[index];
  const result = cache.get(key);
  if (index >= args.length - 1) { // reached last of arg
    if (result && !(result instanceof Cache)) {
      return result; // found actual result
    }
    // no result found, save the result as new entry
    // eslint-disable-next-line prefer-spread
    const toCache = func.apply(undefined, args);
    cache.set(key, toCache);
    return toCache;
  }
  // not finished searching yet
  if (result instanceof Cache) {
    return resolveFromCache(args, index + 1, result, func); // found nested Map, go deeper
  }
  // create nested Cache with key and continue
  const nestedCache = new Cache();
  cache.set(key, nestedCache);
  return resolveFromCache(args, index + 1, nestedCache, func);
}

export function memoize(func: (...args: any[]) => any) {
  const memoized = function (...args: any[]) {
    return resolveFromCache(args, 0, memoized.cache, func);
  };
  memoized.cache = new Cache();
  return memoized;
}
